import React, { Component } from "react"
import { connect, Provider } from "react-redux"
import { applyMiddleware, compose, createStore } from "redux"
import thunk from "redux-thunk"
import {
  getAuthListener,
  removeAuthListener,
} from "../store/actions/authActions"
import { updateFirebase, updateGoogleProvider } from "./actions/utilsActions"
import rootReducer from "./reducers/rootReducer"
import { utilsStateInterface } from "./reducers/utilsReducer"

declare global {
  interface Window {
    fbq: any
    google: any
    opera: any
    MSStream: any
  }
  interface Date {
    seconds: number
  }
  interface File {
    type: string
  }
}

interface ProviderComponent {
  element: any
}

interface Props {
  dispatch: any
  component: ProviderComponent
  utilsStore: utilsStateInterface
}

class AppListener extends Component<Props> {
  componentDidMount = async () => {
    await import("firebase/app")
    await import("firebase/analytics")
    await import("firebase/auth")
    await import("firebase/firestore")
    const firebase = await import("../utils/firebase")
    const googleAuthProvider = new firebase.default.auth.GoogleAuthProvider()
    updateFirebase(this.props.dispatch, firebase.default)
    updateGoogleProvider(this.props.dispatch, googleAuthProvider)
    getAuthListener(this.props.dispatch, firebase.default)
  }

  componentWillUnmount = async () => {
    if (this.props.utilsStore.firebase) {
      removeAuthListener()
    }
  }

  render() {
    if (this.props.utilsStore.firebase) {
      return <>{this.props.component.element}</>
    } else {
      return null
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  }
}

const WrappedAppListener = connect(mapStateToProps)(AppListener)

const wrapWithProvider = (component: ProviderComponent) => {
  //INFO: Store Creation
  const initialState = {}
  const middleware = [thunk]
  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware))
  )
  return (
    <Provider store={store}>
      <WrappedAppListener component={component} />
    </Provider>
  )
}

export default wrapWithProvider
