import axios from "axios"
import { navigate } from "gatsby"
import moment from "moment"
import { UserState, UserStateError } from "../../components/dashboard/AuthCard"
import {
  ProfileState,
  ProfileStateError,
} from "../../components/dashboard/Profile"
import { GenericStats } from "../../models/Promotion"
import { ReferralModel } from "../../models/Referral"
import {
  Age,
  CookFrequency,
  FamilyMembers,
  Gender,
  ProfessionSector,
  PromotionApplied,
  UserModel,
} from "../../models/User"
import { utilPhonePlaceholder, utilPhoneRegex } from "../../utils/region"
import { checkPromoCodeExists } from "./promotionActions"

let unsubscribeAuthListener: any = null
let unsubscribeUserListener: any = null

export const getAuthListener = (dispatch: any, firebase: any) => {
  if (!unsubscribeAuthListener) {
    unsubscribeAuthListener = firebase
      .auth()
      .onAuthStateChanged(function (user: any) {
        if (user) {
          dispatch({
            type: "UPDATE_USER_AUTH",
            payload: {
              userAuth: user,
            },
          })
          getUserInfoListener(dispatch, firebase)
        } else {
          dispatch({
            type: "UPDATE_USER_AUTH",
            payload: {
              userAuth: null,
            },
          })
          removeUserInfoListener()
        }
      })
  }
}

export const getReferrals = (firebase: any) => {
  return async (dispatch: any) => {
    try {
      const referralCollectionQuery = firebase
        .firestore()
        .collection("referrals")
        .where("referralUserId", "==", firebase.auth().currentUser.uid)
        .orderBy("createdAt", "desc")
        .limit(10)

      let referralSnapshot = await referralCollectionQuery.get()

      const referralList: ReferralModel[] = []
      if (referralSnapshot) {
        referralSnapshot.forEach((eachDoc: any) => {
          const eachReferral = eachDoc.data() as ReferralModel
          referralList.push(eachReferral)
        })
      }

      if (referralList.length > 0) {
        const isProd = process.env.GATSBY_FIREBASE_ENV === "production"
        const userDetailsAPILoc = isProd ? "arusoil-web" : "arusoil-web-dev"
        const userDetailsAPI = `https://asia-southeast2-${userDetailsAPILoc}.cloudfunctions.net/getUserDetailsById`
        await Promise.all(
          referralList.map(async (eachRefer, index) => {
            try {
              const userDetailRes = await axios.post(userDetailsAPI, {
                userId: eachRefer.refereeUserId,
              })
              referralList[index]["refereeUserId"] =
                userDetailRes.data.user.name
            } catch (err) {
              referralList[index]["refereeUserId"] = "-"
            }
          })
        )
      }

      dispatch({
        type: "UPDATE_USER_REFERRALS",
        payload: {
          referrals: referralList,
        },
      })
    } catch (err) {
      return err.message
    }
  }
}

export const removeAuthListener = () => {
  if (unsubscribeAuthListener) {
    unsubscribeAuthListener()
    unsubscribeAuthListener = null
  }
  removeUserInfoListener()
}

export const getUserInfoListener = async (dispatch: any, firebase: any) => {
  try {
    if (firebase.auth().currentUser?.uid) {
      unsubscribeUserListener = firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser?.uid)
        .onSnapshot(async (doc: any) => {
          if (doc) {
            dispatch({
              type: "UPDATE_USER",
              payload: {
                user: doc.data(),
              },
            })
          }
        })
    }
  } catch (err) {}
}

const removeUserInfoListener = () => {
  if (unsubscribeUserListener) {
    unsubscribeUserListener()
    unsubscribeUserListener = null
  }
}

export const checkUserExists = async (firebase: any) => {
  try {
    if (firebase.auth().currentUser?.uid) {
      const userQuery = await firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser?.uid)
        .get()
      if (userQuery.exists) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } catch (err) {
    return false
  }
}

export const signIn = async (
  credentials: {
    email: string
    password: string
  },
  firebase: any
) => {
  try {
    await firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
  } catch (err) {
    if (
      err.code.includes("wrong-password") ||
      err.code.includes("user-not-found")
    ) {
      return "Incorrect email or password"
    }
    return err.message
  }
}

export const signOut = async (firebase: any) => {
  try {
    await firebase.auth().signOut()
    navigate("/")
  } catch (err) {}
}

export const resetPassword = async (email: string, firebase: any) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email)
    return ""
  } catch (err) {
    if (err.code.includes("user-not-found")) {
      return "Password reset link has been sent to your email"
    }
    return err.message
  }
}

export const verifyOobCode = async (oobCode: string, firebase: any) => {
  const result = await firebase.auth().verifyPasswordResetCode(oobCode)
  return result
}

export const verifyEmailLink = async (oobCode: string, firebase: any) => {
  await firebase.auth().applyActionCode(oobCode)
}

export const resetPasswordWithOob = async (
  oobCode: string,
  password: string,
  firebase: any
) => {
  await firebase.auth().confirmPasswordReset(oobCode, password)
}

export const createAccount = async (
  userState: UserState,
  promoCode: string,
  referralId: string,
  firebase: any
) => {
  try {
    const createdCredentials = await firebase
      .auth()
      .createUserWithEmailAndPassword(userState.email, userState.password)

    if (createdCredentials.user) {
      const userUUID: string = createdCredentials.user?.uid
      let userModel: UserModel = {
        id: userUUID,
        email: userState.email,
        name: userState.name,
        gender: userState.gender as keyof typeof Gender,
        age: userState.age as keyof typeof Age,
        profession: userState.profession as keyof typeof ProfessionSector,
        mobileNo: userState.mobileNo,
        familyMembers: userState.familyMembers as keyof typeof FamilyMembers,
        createdAt: new Date(),
        cookFrequency: userState.cookFrequency as keyof typeof CookFrequency,
      }
      if (userState.professionString) {
        userModel["professionOther"] = userState.professionString
      }
      await firebase
        .firestore()
        .collection("users")
        .doc(userUUID)
        .set(userModel)

      await createdCredentials.user.sendEmailVerification()

      if (promoCode) {
        handleAddGenericPromoCode(promoCode, [], firebase)
      } else if (referralId) {
        handleCreateReferral(referralId, userUUID, userState.name, firebase)
      }

      const isProd = process.env.GATSBY_FIREBASE_ENV === "production"
      if (isProd) {
        subscribeToEngineMailer(userModel)
        if (window.fbq) {
          window.fbq("track", "CompleteRegistration")
        }
      }

      return ""
    } else return "User is not created. Unknown Error."
  } catch (err) {
    return err.message
  }
}

export const createUserDetails = async (
  userState: UserState,
  promoCode: string,
  referralId: string,
  firebase: any
) => {
  try {
    if (firebase.auth().currentUser?.uid) {
      const userExist = await checkUserExists(firebase)
      if (!userExist) {
        const userId = firebase.auth().currentUser.uid
        let userModel: UserModel = {
          id: userId,
          email: firebase.auth().currentUser.email,
          name: userState.name,
          gender: userState.gender as keyof typeof Gender,
          age: userState.age as keyof typeof Age,
          profession: userState.profession as keyof typeof ProfessionSector,
          mobileNo: userState.mobileNo,
          familyMembers: userState.familyMembers as keyof typeof FamilyMembers,
          createdAt: new Date(),
          cookFrequency: userState.cookFrequency as keyof typeof CookFrequency,
        }
        await firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .set(userModel)

        if (promoCode) {
          handleAddGenericPromoCode(promoCode, [], firebase)
        } else if (referralId) {
          handleCreateReferral(referralId, userId, userState.name, firebase)
        }

        const isProd = process.env.GATSBY_FIREBASE_ENV === "production"
        if (isProd) {
          subscribeToEngineMailer(userModel)
          if (window.fbq) {
            window.fbq("track", "CompleteRegistration")
          }
        }
      } else {
        return "User has existed. Please retry again."
      }
    } else {
      return "User is not created. Unknown Error."
    }
  } catch (err) {
    return err.message
  }
}

export const createAccountEnterprise = async (
  userState: UserState,
  promoCode: string,
  firebase: any
) => {
  try {
    const createdCredentials = await firebase
      .auth()
      .createUserWithEmailAndPassword(userState.email, userState.password)
    if (createdCredentials.user) {
      const userUUID: string = createdCredentials.user?.uid
      let userModel: UserModel = {
        id: userUUID,
        email: userState.email,
        name: userState.name,
        gender: userState.gender as keyof typeof Gender,
        age: userState.age as keyof typeof Age,
        profession: userState.profession as keyof typeof ProfessionSector,
        mobileNo: userState.mobileNo,
        familyMembers: userState.familyMembers as keyof typeof FamilyMembers,
        createdAt: new Date(),
        cookFrequency: userState.cookFrequency as keyof typeof CookFrequency,
      }
      if (userState.professionString) {
        userModel["professionOther"] = userState.professionString
      }
      await firebase
        .firestore()
        .collection("users")
        .doc(userUUID)
        .set(userModel)

      await createdCredentials.user.sendEmailVerification()

      if (userState.enterprise) {
        let fileName = ""
        if (userState.enterprise.type.match("image*")) {
          fileName = "ssm.jpg"
        } else {
          fileName = "ssm.pdf"
        }

        const storage = firebase.storage().ref("users")
        const userStore = firebase.firestore().collection("users").doc(userUUID)
        const uploadTask = await storage
          .child(userUUID + "/" + fileName)
          .put(userState.enterprise)
        const uploadTaskUrl: string = await uploadTask.ref.getDownloadURL()
        const urlParams = new URLSearchParams(uploadTaskUrl)
        const fileToken = urlParams.get("token")
        await userStore.update({
          enterprise: fileName,
          enterpriseToken: fileToken,
          enterpriseStatus: "PEND",
        })
      }

      if (promoCode) {
        handleAddGenericPromoCode(promoCode, [], firebase)
      }

      const isProd = process.env.GATSBY_FIREBASE_ENV === "production"
      if (isProd) {
        subscribeToEngineMailer(userModel)
        if (window.fbq) {
          window.fbq("track", "CompleteRegistration")
        }
      }

      const content = `Hi ${userModel.name},<br><br>We have received your interest in creating an Arus Oil enterprise account. Please allow 1-2 working days for us to validate your application. You will receive an email notification on your application updates. Once your application has been approved, enterprise pricing of recycling used cooking oil will be reflected. For any inquiries, please do not hesitate to contact us at info@arusoil.com.<br><br>Thanks,<br>Arus Oil`

      await axios.post(
        "https://api.enginemailer.com/RESTAPI/Submission/SendEmail",
        {
          UserKey: process.env.GATSBY_EMAIL_SEND_API,
          ToEmail: userModel.email,
          SenderEmail: "noreply@arusoil.com",
          SenderName: "Arus Oil",
          Subject: `[Arus Oil] Arus Oil Enterprise Account Creation`,
          SubmittedContent: content,
        }
      )

      return ""
    } else return "User is not created. Unknown Error."
  } catch (err) {
    return err.message
  }
}

const handleCreateReferral = async (
  referralId: string,
  userId: string,
  userName: string,
  firebase: any
) => {
  try {
    const isProd = process.env.GATSBY_FIREBASE_ENV === "production"
    const userDetailsAPILoc = isProd ? "arusoil-web" : "arusoil-web-dev"
    const userDetailsAPI = `https://asia-southeast2-${userDetailsAPILoc}.cloudfunctions.net/getUserDetailsById`

    const userDetailRes = await axios.post(userDetailsAPI, {
      userId: referralId,
    })

    const referralRef = firebase.firestore().collection("referrals").doc()
    const newReferralId = referralRef.id
    const newReferral: ReferralModel = {
      id: newReferralId,
      referralUserId: referralId,
      refereeUserId: userId,
      activated: false,
      activationDate: moment().toDate(),
      createdAt: moment().toDate(),
    }
    await referralRef.set(newReferral)

    const referralName = userDetailRes.data.user.name
    const content = `Hi ${referralName},<br><br>Your friend, ${userName} has joined Arus Oil Family to start recycling used cooking oil.<br><br> Once your friend has recycled his/her used cooking oil at least once, both of you will be entitled to a 10% increase in the purchasing rate of used cooking oil.<br><br>Thanks,<br>Arus Oil`

    await axios.post(
      "https://api.enginemailer.com/RESTAPI/Submission/SendEmail",
      {
        UserKey: process.env.GATSBY_EMAIL_SEND_API,
        ToEmail: userDetailRes.data.user.email,
        SenderEmail: "noreply@arusoil.com",
        SenderName: "Arus Oil",
        Subject: `[Arus Oil] Your friend, ${userName} has joined Arus Oil`,
        SubmittedContent: content,
      }
    )
  } catch (err) {}
}

export const handleAddGenericPromoCode = async (
  promoCode: string,
  promotion: PromotionApplied[],
  firebase: any
) => {
  try {
    if (promoCode) {
      const currentUser = await firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser?.uid)
        .get()

      if (currentUser.exists) {
        const userData = currentUser.data() as UserModel
        let promoRedeemed = false
        if (userData.promotions && userData.promotions.length > 0) {
          userData.promotions.map(eachPromo => {
            if (eachPromo.id === promoCode) {
              promoRedeemed = true
            }
            return null
          })
        }

        if (promoRedeemed) {
          return "You have redeemed this promotion code before"
        }
      } else {
        return "User does not exists"
      }

      const promotionExists = await checkPromoCodeExists(promoCode, firebase)
      if (promotionExists.exists) {
        const redemptionCounter =
          promotionExists.data().genericStats.redemptionCounter + 1

        const redemptionLimit = promotionExists.data().genericStats
          .redemptionLimit

        if (redemptionCounter > redemptionLimit) {
          return "The promotion code has been redeemed finish."
        } else {
          const genericStats: GenericStats = {
            redemptionCounter: redemptionCounter,
            redemptionLimit: redemptionLimit,
          }
          promotion.push({
            id: promoCode,
            appliedDate: moment().toDate(),
          })

          await firebase
            .firestore()
            .collection("users")
            .doc(firebase.auth().currentUser?.uid)
            .update({ promotions: promotion })

          await firebase
            .firestore()
            .collection("promotion")
            .doc(promoCode)
            .update({ genericStats })
          return ""
        }
      } else {
        return "Promotion code is invalid"
      }
    } else {
      return "Promotion code is invalid"
    }
  } catch (err) {
    return err.message
  }
}

const subscribeToEngineMailer = async (eachUser: UserModel) => {
  try {
    const headers = {
      APIKey: process.env.GATSBY_EMAIL_API,
    }

    await axios.post(
      "https://api.enginemailer.com/restapi/subscriber/emsubscriber/insertSubscriber",
      {
        email: eachUser.email,
        customfields: [
          {
            customfield_key: "first_name",
            customfield_value: eachUser.name,
          },
          {
            customfield_key: "mobile",
            customfield_value: eachUser.mobileNo,
          },
          {
            customfield_key: "created_at",
            customfield_value: moment(eachUser.createdAt).format(
              "DD-MMMM-YYYY"
            ),
          },
        ],
      },
      { headers: headers }
    )
  } catch (err) {}
}

export const handleUpdateField = async (
  type: String,
  value: any,
  firebase: any
) => {
  try {
    switch (type) {
      case "profile": {
        await firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser?.uid)
          .update({
            name: value.name,
            address: value.address,
            age: value.age,
            gender: value.gender,
            mobileNo: value.mobileNo,
            profession: value.profession,
            professionOther: value.professionOther,
            familyMembers: value.familyMembers,
            cookFrequency: value.cookFrequency,
          })
        return ""
      }
      case "image": {
        const uploadTask = await firebase
          .storage()
          .ref("users")
          .child(firebase.auth().currentUser?.uid + "/profile.jpg")
          .put(value)
        const uploadTaskUrl: string = await uploadTask.ref.getDownloadURL()
        const urlParams = new URLSearchParams(uploadTaskUrl)
        const imageToken = urlParams.get("token")
        await firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser?.uid)
          .update({ image: imageToken })
        return ""
      }
      case "bankInfo": {
        await firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser?.uid)
          .update({
            bankName: value.bankName,
            bankAccountName: value.bankAccountName,
            bankAccountNo: value.bankAccountNo,
          })
        return ""
      }
    }
  } catch (err) {
    return err.message
  }
}

export const handleRemoveProfile = async (firebase: any) => {
  try {
    await firebase
      .storage()
      .ref("users")
      .child(firebase.auth().currentUser?.uid + "/profile.jpg")
      .delete()
    await firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser?.uid)
      .update({ image: "" })
    return ""
  } catch (err) {
    return err.message
  }
}

export const signInWithGoogle = async (firebase: any, provider: any) => {
  try {
    await firebase.auth().signInWithPopup(provider)
  } catch (err) {
    return err.message
  }
}

export const handleProfileCondition = (
  userProfileAttributeState: ProfileState,
  userProfileAttributeError: ProfileStateError,
  typeList: string[]
) => {
  typeList.map(type => {
    switch (type) {
      case "name":
        if (userProfileAttributeState.name.replace(/\s/g, "").length <= 0) {
          userProfileAttributeError["nameError"] = "Please enter your name"
        } else {
          userProfileAttributeError["nameError"] = ""
        }
        break
      case "address":
        if (
          userProfileAttributeState.address.name.replace(/\s/g, "").length <= 0
        ) {
          userProfileAttributeError["addressError"] =
            "Please select your address from the suggestions given"
        } else {
          userProfileAttributeError["addressError"] = ""
        }
        break
      case "age":
        if (!userProfileAttributeState.age) {
          userProfileAttributeError["ageError"] = "Please select your age range"
        } else {
          userProfileAttributeError["ageError"] = ""
        }
        break
      case "gender":
        if (!userProfileAttributeState.gender) {
          userProfileAttributeError["genderError"] = "Please select your gender"
        } else {
          userProfileAttributeError["genderError"] = ""
        }
        break
      case "mobileNo":
        const phoneFilter = utilPhoneRegex()
        if (!phoneFilter.test(userProfileAttributeState.mobileNo)) {
          userProfileAttributeError[
            "mobileNoError"
          ] = `Please enter your contact number in the correct format of ${utilPhonePlaceholder()} (Example)`
        } else {
          userProfileAttributeError["mobileNoError"] = ""
        }
        break
      case "profession":
        if (!userProfileAttributeState.profession) {
          userProfileAttributeError["professionError"] =
            "Please select your profession sector"
        } else {
          userProfileAttributeError["professionError"] = ""
        }
        break
      case "professionOther":
        if (
          userProfileAttributeState.professionOther.replace(/\s/g, "").length <=
          0
        ) {
          userProfileAttributeError["professionOtherError"] =
            "Please enter your profession"
        } else {
          userProfileAttributeError["professionOtherError"] = ""
        }
        break
      case "familyMembers":
        if (!userProfileAttributeState.familyMembers) {
          userProfileAttributeError["familyMembersError"] =
            "Please select the number of your family members"
        } else {
          userProfileAttributeError["familyMembersError"] = ""
        }
        break
      case "cookFrequency":
        if (!userProfileAttributeState.cookFrequency) {
          userProfileAttributeError["cookFrequencyError"] =
            "Please select your cooking frequency"
        } else {
          userProfileAttributeError["cookFrequencyError"] = ""
        }
        break
    }
    return null
  })
}

export const handleAuthCondition = (
  userAttributeState: UserState,
  userAttributeError: UserStateError,
  typeList: string[]
) => {
  typeList.map(eachType => {
    switch (eachType) {
      case "name":
        if (userAttributeState.name.replace(/\s/g, "").length <= 0) {
          userAttributeError["nameError"] = "Please enter your name"
        } else {
          userAttributeError["nameError"] = ""
        }
        break
      case "mobileNo":
        const phoneFilter = utilPhoneRegex()
        if (!phoneFilter.test(userAttributeState.mobileNo)) {
          userAttributeError[
            "mobileNoError"
          ] = `Please enter your contact number in the correct format of ${utilPhonePlaceholder()} (Example)`
        } else {
          userAttributeError["mobileNoError"] = ""
        }
        break
      case "gender":
        if (!userAttributeState.gender) {
          userAttributeError["genderError"] = "Please select your gender"
        } else {
          userAttributeError["genderError"] = ""
        }
        break
      case "age":
        if (!userAttributeState.age) {
          userAttributeError["ageError"] = "Please select your age range"
        } else {
          userAttributeError["ageError"] = ""
        }
        break
      case "profession":
        if (!userAttributeState.profession) {
          userAttributeError["professionError"] =
            "Please select your profession sector"
        } else {
          userAttributeError["professionError"] = ""
        }
        break
      case "enterprise":
        if (userAttributeState.enterprise) {
          const fileExtensionFilter = /.(jpg|jpeg|png|pdf)$/
          const fileSize = userAttributeState.enterprise.size / 1024 / 1024
          if (!fileExtensionFilter.test(userAttributeState.enterprise.name)) {
            userAttributeError["enterpriseError"] =
              "Please upload an image/pdf file"
          } else if (fileSize > 5) {
            userAttributeError["enterpriseError"] =
              "File size cannot exceed 5MB"
          } else {
            userAttributeError["enterpriseError"] = ""
          }
        } else {
          userAttributeError["enterpriseError"] =
            "Please upload an image/pdf file"
        }
        break
      case "familyMembers":
        if (!userAttributeState.familyMembers) {
          userAttributeError["familyMembersError"] =
            "Please select the number of your family members"
        } else {
          userAttributeError["familyMembersError"] = ""
        }
        break
      case "cookFrequency":
        if (!userAttributeState.cookFrequency) {
          userAttributeError["cookFrequencyError"] =
            "Please select your cooking frequency"
        } else {
          userAttributeError["cookFrequencyError"] = ""
        }
        break
      case "email":
        //eslint-disable-next-line
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
        if (!filter.test(userAttributeState.email.replace(/\s/g, ""))) {
          userAttributeError["emailError"] =
            "Please enter your email in the correct format"
        } else {
          userAttributeError["emailError"] = ""
        }
        break
      case "password":
        if (userAttributeState.password.replace(/\s/g, "").length < 6) {
          userAttributeError["passwordError"] =
            "Please enter at least 6 characters"
        } else {
          userAttributeError["passwordError"] = ""
        }
        break
      case "professionString":
        if (userAttributeState.profession === "OTHERS") {
          if (
            userAttributeState.professionString.replace(/\s/g, "").length <= 0
          ) {
            userAttributeError["professionStringError"] =
              "Please enter your profession"
          } else {
            userAttributeError["professionStringError"] = ""
          }
        } else {
          userAttributeError["professionStringError"] = ""
        }
        break
    }
    return null
  })
}

export const handleBankCondition = (
  userProfileAttributeState: ProfileState,
  userProfileAttributeError: ProfileStateError
) => {
  if (!userProfileAttributeState.bankName) {
    userProfileAttributeError.bankNameError = "Please select a valid bank."
  } else {
    userProfileAttributeError.bankNameError = ""
  }

  if (
    userProfileAttributeState.bankAccountName.replace(/\s/g, "").length <= 0
  ) {
    userProfileAttributeError.bankAccNameError =
      "Please enter valid bank account name."
  } else {
    userProfileAttributeError.bankAccNameError = ""
  }

  if (userProfileAttributeState.bankAccountNo.replace(/\s/g, "").length <= 0) {
    userProfileAttributeError.bankAccNoError =
      "Please enter valid bank account number."
  } else {
    userProfileAttributeError.bankAccNoError = ""
  }
}
