import { Language } from "../reducers/utilsReducer"

export const updateFirebase = (dispatch: any, firebase: any) => {
  dispatch({
    type: "UPDATE_FIREBASE",
    payload: {
      firebase: firebase,
    },
  })
}

export const updateLanguage = (language: Language) => {
  return async (dispatch: any) => {
    dispatch({
      type: "UPDATE_LANGUAGE",
      payload: {
        language: language,
      },
    })
  }
}

export const updateGoogleProvider = (
  dispatch: any,
  googleAuthProvider: any
) => {
  dispatch({
    type: "UPDATE_GOOGLE_AUTH_PROVIDER",
    payload: {
      googleAuthProvider: googleAuthProvider,
    },
  })
}

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
