// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/aboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-auth-enterprise-tsx": () => import("./../../../src/pages/auth/enterprise.tsx" /* webpackChunkName: "component---src-pages-auth-enterprise-tsx" */),
  "component---src-pages-auth-sign-in-tsx": () => import("./../../../src/pages/auth/signIn.tsx" /* webpackChunkName: "component---src-pages-auth-sign-in-tsx" */),
  "component---src-pages-auth-sign-up-tsx": () => import("./../../../src/pages/auth/signUp.tsx" /* webpackChunkName: "component---src-pages-auth-sign-up-tsx" */),
  "component---src-pages-auth-verification-tsx": () => import("./../../../src/pages/auth/verification.tsx" /* webpackChunkName: "component---src-pages-auth-verification-tsx" */),
  "component---src-pages-bm-about-us-tsx": () => import("./../../../src/pages/bm/aboutUs.tsx" /* webpackChunkName: "component---src-pages-bm-about-us-tsx" */),
  "component---src-pages-bm-carbon-savings-tsx": () => import("./../../../src/pages/bm/carbonSavings.tsx" /* webpackChunkName: "component---src-pages-bm-carbon-savings-tsx" */),
  "component---src-pages-bm-faqs-tsx": () => import("./../../../src/pages/bm/faqs.tsx" /* webpackChunkName: "component---src-pages-bm-faqs-tsx" */),
  "component---src-pages-bm-index-tsx": () => import("./../../../src/pages/bm/index.tsx" /* webpackChunkName: "component---src-pages-bm-index-tsx" */),
  "component---src-pages-bm-jmb-initiative-tsx": () => import("./../../../src/pages/bm/jmbInitiative.tsx" /* webpackChunkName: "component---src-pages-bm-jmb-initiative-tsx" */),
  "component---src-pages-bm-ngo-initiative-tsx": () => import("./../../../src/pages/bm/ngoInitiative.tsx" /* webpackChunkName: "component---src-pages-bm-ngo-initiative-tsx" */),
  "component---src-pages-bm-privacypolicy-tsx": () => import("./../../../src/pages/bm/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-bm-privacypolicy-tsx" */),
  "component---src-pages-bm-recycle-uco-tsx": () => import("./../../../src/pages/bm/recycleUCO.tsx" /* webpackChunkName: "component---src-pages-bm-recycle-uco-tsx" */),
  "component---src-pages-bm-refer-friend-tsx": () => import("./../../../src/pages/bm/referFriend.tsx" /* webpackChunkName: "component---src-pages-bm-refer-friend-tsx" */),
  "component---src-pages-bm-referral-faqs-tsx": () => import("./../../../src/pages/bm/referralFaqs.tsx" /* webpackChunkName: "component---src-pages-bm-referral-faqs-tsx" */),
  "component---src-pages-bm-termscondition-tsx": () => import("./../../../src/pages/bm/termscondition.tsx" /* webpackChunkName: "component---src-pages-bm-termscondition-tsx" */),
  "component---src-pages-carbon-savings-tsx": () => import("./../../../src/pages/carbonSavings.tsx" /* webpackChunkName: "component---src-pages-carbon-savings-tsx" */),
  "component---src-pages-ch-about-us-tsx": () => import("./../../../src/pages/ch/aboutUs.tsx" /* webpackChunkName: "component---src-pages-ch-about-us-tsx" */),
  "component---src-pages-ch-carbon-savings-tsx": () => import("./../../../src/pages/ch/carbonSavings.tsx" /* webpackChunkName: "component---src-pages-ch-carbon-savings-tsx" */),
  "component---src-pages-ch-faqs-tsx": () => import("./../../../src/pages/ch/faqs.tsx" /* webpackChunkName: "component---src-pages-ch-faqs-tsx" */),
  "component---src-pages-ch-index-tsx": () => import("./../../../src/pages/ch/index.tsx" /* webpackChunkName: "component---src-pages-ch-index-tsx" */),
  "component---src-pages-ch-jmb-initiative-tsx": () => import("./../../../src/pages/ch/jmbInitiative.tsx" /* webpackChunkName: "component---src-pages-ch-jmb-initiative-tsx" */),
  "component---src-pages-ch-ngo-initiative-tsx": () => import("./../../../src/pages/ch/ngoInitiative.tsx" /* webpackChunkName: "component---src-pages-ch-ngo-initiative-tsx" */),
  "component---src-pages-ch-privacypolicy-tsx": () => import("./../../../src/pages/ch/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-ch-privacypolicy-tsx" */),
  "component---src-pages-ch-recycle-uco-tsx": () => import("./../../../src/pages/ch/recycleUCO.tsx" /* webpackChunkName: "component---src-pages-ch-recycle-uco-tsx" */),
  "component---src-pages-ch-referral-faqs-tsx": () => import("./../../../src/pages/ch/referralFaqs.tsx" /* webpackChunkName: "component---src-pages-ch-referral-faqs-tsx" */),
  "component---src-pages-ch-termscondition-tsx": () => import("./../../../src/pages/ch/termscondition.tsx" /* webpackChunkName: "component---src-pages-ch-termscondition-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jmb-initiative-tsx": () => import("./../../../src/pages/jmbInitiative.tsx" /* webpackChunkName: "component---src-pages-jmb-initiative-tsx" */),
  "component---src-pages-ngo-initiative-tsx": () => import("./../../../src/pages/ngoInitiative.tsx" /* webpackChunkName: "component---src-pages-ngo-initiative-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-recycle-uco-tsx": () => import("./../../../src/pages/recycleUCO.tsx" /* webpackChunkName: "component---src-pages-recycle-uco-tsx" */),
  "component---src-pages-refer-friend-tsx": () => import("./../../../src/pages/referFriend.tsx" /* webpackChunkName: "component---src-pages-refer-friend-tsx" */),
  "component---src-pages-referral-faqs-tsx": () => import("./../../../src/pages/referralFaqs.tsx" /* webpackChunkName: "component---src-pages-referral-faqs-tsx" */),
  "component---src-pages-referrals-tsx": () => import("./../../../src/pages/referrals.tsx" /* webpackChunkName: "component---src-pages-referrals-tsx" */),
  "component---src-pages-termscondition-tsx": () => import("./../../../src/pages/termscondition.tsx" /* webpackChunkName: "component---src-pages-termscondition-tsx" */)
}

