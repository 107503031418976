import moment from "moment"
import PromotionModel from "../../models/Promotion"
import { ReferralModel } from "../../models/Referral"

export const getPromotionList = (firebase: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const promotionsId: string[] = []
      getState().authStore.user.promotions.map((eachPromotion: any) => {
        promotionsId.push(eachPromotion.id)
        return ""
      })

      const promotionList: PromotionModel[] = []
      if (promotionsId.length > 0) {
        while (promotionsId.length > 0) {
          const splicePromotionList = promotionsId.splice(0, 10)
          const promotionSnapshot = await firebase
            .firestore()
            .collection("promotion")
            .where("id", "in", splicePromotionList)
            .get()
          if (promotionSnapshot) {
            promotionSnapshot.forEach((eachDoc: any) => {
              const eachPromotion = eachDoc.data() as PromotionModel
              promotionList.push(eachPromotion)
            })
          }
        }

        dispatch({
          type: "UPDATE_PROMO_LIST",
          payload: {
            promotions: promotionList,
          },
        })
      }
    } catch (err) {
      return err.message
    }
  }
}

export const clearPromotion = () => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_PROMO_LIST",
      payload: {
        promotions: [],
      },
    })
  }
}

export const updatePromotionCode = (promotionCode: string) => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_PROMO_CODE",
      payload: {
        promotionCode: promotionCode,
      },
    })
  }
}

export const updateReferralId = (referralId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_REFERRAL_ID",
      payload: {
        referralId: referralId,
      },
    })
  }
}

export const handlePromotionCondition = (promotionAttributeState: string) => {
  if (promotionAttributeState.replace(/\s/g, "").length <= 0) {
    return "Please enter the promotion code"
  }
}

export const checkPromoCodeExists = async (
  promoCode: string,
  firebase: any
) => {
  try {
    const promotionQuery = await firebase
      .firestore()
      .collection("promotion")
      .doc(promoCode)
      .get()
    return promotionQuery
  } catch (err) {
    return false
  }
}

export const checkReferralExist = async (firebase: any) => {
  const currentUserUid = firebase.auth().currentUser?.uid
  if (currentUserUid) {
    try {
      const referralSnapshot = await firebase
        .firestore()
        .collection("referrals")
        .where("activated", "==", true)
        .where("referralUserId", "==", currentUserUid)
        .orderBy("activationDate", "desc")
        .limit(1)
        .get()

      const refereeSnapshot = await firebase
        .firestore()
        .collection("referrals")
        .where("activated", "==", true)
        .where("refereeUserId", "==", currentUserUid)
        .orderBy("activationDate", "desc")
        .limit(1)
        .get()

      const referralList: ReferralModel[] = []
      if (referralSnapshot) {
        referralSnapshot.forEach((eachDoc: any) => {
          const eachReferral = eachDoc.data() as ReferralModel
          referralList.push(eachReferral)
        })
      }
      if (refereeSnapshot) {
        refereeSnapshot.forEach((eachDoc: any) => {
          const eachReferral = eachDoc.data() as ReferralModel
          referralList.push(eachReferral)
        })
      }

      referralList.sort(function (a, b) {
        return moment(a.activationDate.seconds * 1000).isAfter(
          moment(b.activationDate.seconds * 1000)
        )
          ? -1
          : 0
      })

      if (referralList.length > 0) {
        const selectedReferral = referralList[0]
        const promotionAppliedDate = moment(
          selectedReferral.activationDate.seconds * 1000
        )
        promotionAppliedDate.add(3, "month")
        if (moment().isSameOrBefore(promotionAppliedDate)) {
          return true
        }
      }
      return false
    } catch (err) {
      return false
    }
  }
}
