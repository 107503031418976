const mediumLoc = process.env.GATSBY_MEDIUM

export const firstTier = mediumLoc === "brunei" ? 0.08 : 1.3
export const secondTier = mediumLoc === "brunei" ? 0.1 : 1.6
export const thirdTier = mediumLoc === "brunei" ? 0.12 : 1.9
export const fourthTier = mediumLoc === "brunei" ? 0.14 : 2.2

export const utilCurrency = () => {
  if (mediumLoc === "brunei") {
    return "B$"
  } else {
    return "RM"
  }
}

export const utilPhoneRegex = () => {
  if (mediumLoc === "brunei") {
    return /^(\+673)-*[0-9]{7}$/
  } else {
    return /^(\+60)-*[0-9]{8,10}$/
  }
}

export const utilPhonePlaceholder = () => {
  if (mediumLoc === "brunei") {
    return "+6731234567"
  } else {
    return "+60123456789"
  }
}
