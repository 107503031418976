export interface utilsStateInterface {
  firebase: any
  language: Language
  googleAuthProvider: any
}

export enum Language {
  EN = "English",
  BM = "Malay",
  CH = "Chinese",
}

const initState: utilsStateInterface = {
  firebase: null,
  language: Language.EN,
  googleAuthProvider: null,
}

const utilsReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_FIREBASE":
      return {
        ...state,
        firebase: action.payload.firebase,
      }
    case "UPDATE_LANGUAGE":
      return {
        ...state,
        language: action.payload.language,
      }
    case "UPDATE_GOOGLE_AUTH_PROVIDER":
      return {
        ...state,
        googleAuthProvider: action.payload.googleAuthProvider,
      }
    default:
      return state
  }
}

export default utilsReducer
